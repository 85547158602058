<template>
  <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
    <div class="card  mb-5">
      <div class="card-body">
        <div class="row justify-content-center">
          <div style="width:500px;">
              <el-steps :space="200" :active="this.currentRouteName" finish-status="success" align-center>
                <el-step title="Paket Adı" />
                <el-step title="İçerik" />
                <el-step title="Genel" />
                <el-step title="Fiyatlandırma" />
              </el-steps>
          </div>
        </div>
      </div>
    </div>
    <router-view v-slot="{ Component }">
        <transition 
            name="custom-classes"
            @enter="onEnter"
            @leave="onLeave"
            mode="out-in" 
            enter-active-class="animate__animated animate__bounceInRight" 
            leave-to-class="animate__animated animate__bounceOutLeft">
            <component :is="Component" />
        </transition>
    </router-view>
    <div class="card" v-if="showButton && !isFinished">
      <div class="card-body">
        <div class="row mt-3">
          <div class="col text-end">
            
            <router-link 
                v-if="!isInFirstStep"
                replace
                :to="goBack">
                <button class="btn btn-success"> Geri Git </button>
            </router-link>
            <router-link 
                v-if="!isInFinalStep"
                replace

                :to="nextPage">
                <button class="btn btn-success ms-2"> Devam Et </button>
              
            </router-link>
            <router-link
                v-if="isInFinalStep "
                replace
                to="final" >
              <button @click="savePaket"  class="btn btn-success ms-2"> Paketi Kaydet </button>
            </router-link>
            
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

import store from "@/store";
import useCrud from '@/composables/useCrud.js';
import { Mutations } from "@/store/enums/StoreEnums";

export default {

  
  
  data() {
    return {
      showButton:true,
    }
  },
  computed: {
    currentRouteName() {
     
        return this.$route.path.split("/").pop() - 1
    },
    nextPage(){
      return (parseInt(this.$route.path.split("/").pop()) + 1).toString();
    },
    goBack(){
      var page = (parseInt(this.$route.path.split("/").pop()) - 1).toString();
      if(page <= "1"){
        return "1";
      }
      return page;
    },
    isFinished(){
      if( this.$route.path.split("/").pop() == "final"){
        return true;
      }
      else {
        return false;
      }
    },
    isInFirstStep(){
      if(this.$route.path.split("/").pop() == '1'){
        return true;
      }
      else {
        return false;
      }
    },
    isInFinalStep(){
      if(this.$route.path.split("/").pop() == '4'){
        return true;
      }
      else {
        return false;
      }
    }
  },
  methods: {
    onEnter: function(){
      this.showButton = true;
    },
    onLeave: function(){
      this.showButton = false;
    },
    savePaket: function(){
      const { saveCrud } = useCrud();
      let paket = store.getters.packageItem;
      saveCrud({slug:'/packages',data:paket,}).then(
          function(value) {
            console.log("ekleme başarılı");
            store.commit(Mutations.RESET_PACKAGE);
          }
      );


    }
  },
};
</script>

<style>

</style>